/*eslint-disable */
// import UserBillDetailsModel from '../../Model/UserBillDetails'
import request from '../../Utils/curl'
import Utility from '../../Utils/utility';

let UserBillDetails = {
  /**
   * UserBillDetailsList
   */
  async UserBillDetailsList (context, userId=null) {
    try{
      let post_data = new FormData();
  
      if (userId) {
        post_data.append('user_id', userId);
      }

      return await request.curl(context, "UserBillDetails_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at UserBillDetailsList() and Exception:',err.message)
    }
  },

  /**
   * UserBillDetailsView
   */
  async UserBillDetailsView(context, userBillDetailsObj) {
    try {
      
      let post_data = new FormData();

      for (let key in userBillDetailsObj) {
          if (userBillDetailsObj[key]) {
            post_data.append(key, userBillDetailsObj[key]);
          }
      }

      return await request.curl(context, "UserBillDetails_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at UserBillDetailsView() and Exception:",err.message)
    }
    
  },

  /**
   * UserBillDetailsAdd
   */
  async UserBillDetailsAdd (context, UserBillDetailsObj) {
    try{
    Utility.loadingMsg = "Saving..."
    Utility.showLoader = true
    let post_data = new FormData();
    
    let s3Attachments = []
    for (let key in UserBillDetailsObj) {
      if( key === 'cheque_img_list' && UserBillDetailsObj.cheque_img_list && UserBillDetailsObj.cheque_img_list.length ){
        for (let attachment of UserBillDetailsObj.cheque_img_list) {
          if (attachment.url && attachment.url.includes('amazonaws.com')) {
            // if file is already uploaded
            s3Attachments.push(attachment);
            continue
          }
          post_data.append(key, attachment);
        }
      }
      else {
        if (UserBillDetailsObj[key]) {
          post_data.append(key, UserBillDetailsObj[key]);
        }
      }
    }
    post_data.append('s3Attachments', JSON.stringify(s3Attachments))

    return await request.curl(context, "UserBillDetails_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at UserBillDetailsAdd() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * UserBillDetailsEdit
   */
  async UserBillDetailsEdit (context, UserBillDetailsObj) {
    try {
      let post_data = new FormData();
      Utility.loadingMsg = "Saving..."
      Utility.showLoader = true

      let s3Attachments = []
      for (let key in UserBillDetailsObj) {
        if( key === 'cheque_img_list' && UserBillDetailsObj.cheque_img_list && UserBillDetailsObj.cheque_img_list.length ){
          for (let attachment of UserBillDetailsObj.cheque_img_list) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          if (UserBillDetailsObj[key]) {
            post_data.append(key, UserBillDetailsObj[key]);
          }
        }
      }
      post_data.append('s3Attachments', JSON.stringify(s3Attachments))

    return await request.curl(context, "UserBillDetails_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at UserBillDetailsEdit() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * UserBillDetailsDelete
   */
  async UserBillDetailsDelete (context, UserBillDetailsId) {
    try{
    let post_data = new FormData();
    
    post_data.append('bill_id', UserBillDetailsId);

    return await request.curl(context, "UserBillDetails_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at UserBillDetailsDelete() and Exception:',err.message)
    }
  }
}

export {
  UserBillDetails
}
