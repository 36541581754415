/*eslint-disable */
let config = {
  ClassList: [
    /*
    {
      class_name: 'Masters / Postgraduate',
    }
    {
      class_name: 'Bachelors / Undergraduate',
    },
    */
    {
      class_name: 12,
    },
    {
      class_name: 11,
    },
    {
      class_name: 10,
    },
    {
      class_name: 9,
    },
    {
      class_name: 8,
    },
    {
      class_name: 7,
    }
  ],
  BoardObjList: [
    {
      board_name: 'STATE BOARD',
    },
    {
      board_name: 'CBSE',
    },
    {
      board_name: 'ICSE',
    },
    {
      board_name: 'ISC',
    },
    {
      board_name: 'IGCSE',
    },
    {
      board_name: 'IB',
    },
    {
      board_name: 'INTERNATIONAL',
    },
    {
      board_name: 'OTHER',
    }
  ],  
  BoardList: [
    'STATE BOARD',
    'CBSE',
    'IB',
    'ISC',
    'ICSE',
    'IGCSE',
    'INTERNATIONAL',
    'OTHER'
  ],
  AttachmentTagListObj: {
    Certificates: 'Certificates',
    Marksheets: 'Marksheets',
    Transcripts: 'Transcripts',
    RecommendationLetter: 'Recommendation Letter',
    SOP: 'Statement of Purpose',
    ExperienceLetter: 'Experience Letter',
    OtherDocuments: 'Other Documents'
  },
  ExtraCurricularTypeList: [
    "Awards & Recognitions",
    "Community Service",
    "Internship & Workshops",
    "Leadership Roles",
    "Literary | Poetry | Writing",
    "Model United Nations",
    "Others",
    "Performing Arts | Fine Arts",
    "Speech & Debate",
    "Sports & Atheletics",
    "Summer Programs"
  ],
  PaymentGateWays: {
    stripe: 'PAYGWSTRIP',
    razorpay: 'PAYGWRAZOR',
    appleiap: "PAYGWAPPLEIAP",
    bank: 'BANK',
    gideaffcode: "PAYGWAFFCODE", // CSV Upload
    gidePlusCSV: "PAYGWGIDECSV"
  },
  UserProfileUnlockState: {
    STU_ADM_START: "STU_ADM_START",
    GADMIN_APP_NEED_INFO: "GADMIN_APP_NEED_INFO",
    UNIV_APP_NEED_INFO: "UNIV_APP_NEED_INFO",
    UNIV_APP_ACP_NEED_INFO: "UNIV_APP_ACP_NEED_INFO"
  },
  AdmissionNeedInfoReasons: [
    "Test scores missing",
    "Transcripts/Marksheet missing",
    "Essay/SOP not submitted",
    "LoR not submitted",
    "Incorrect email/mobile number",
    "Address is missing",
    "Need education details of 10th, 11th and 12th standards",
    "Subject marks missing",
    "Update extracurricular activities if any",
    'Other'
  ],
  StudentRejectionReasons: [
    'Not Studying Abroad', 'Budget Constraints', 'Percentage/Grade Not at Par',
    'Program Not Available', 'Looking for Other Country', 'Not Studying Abroad',
    'Budget Constrants', 'Offer from Other University', 'Looking at Other Program',
    'Looking at Other Country', 'Didn’t Get Desired Scholarship',
    'Other'
  ],
  AdmissionStateInfo: {
    STU_ADM_START: 'Form Filling Inprogress',
    STU_APP_DONE: 'Application Done',
    GADMIN_APP_NEED_INFO: 'GIDE Needs More Info',
    GADMIN_APP_REJECTED: 'Appl. on Gide Waitlist',
    GADMIN_STU_APP_RESUBMIT: "Resubmitted to GIDE",
    UNIV_APP_RECVD: 'Appl. Recvd. by Univ',
    UNIV_APP_NEED_INFO: 'Univ Needs More Info',
    UNIV_STU_APP_RESUBMIT: "Resubmitted to Univ",
    UNIV_APP_REJECTED: 'Appl. on Univ Waitlist',
    UNIV_APP_ACCEPTED: 'Appl. Accepted',
    UNIV_APP_ACP_NEED_INFO: 'Univ Needs More Info',
    UNIV_APP_ACP_RESUBMIT: "Resubmitted to Univ",
    UNIV_APP_OFFER_SENT: 'Offers Sent by Univ',
    STU_APP_REJECTED: "Offers Declined",
    STU_APP_ACCEPTED: "Offers Accepted"
  },
  AdmissionStateNum: {
    STU_ADM_START: 1, //'Form Filling Inprogress',
    STU_APP_DONE: 2, //'Application Done',
    GADMIN_APP_NEED_INFO: 3, //'GIDE Needs More Info',
    GADMIN_APP_REJECTED: 4, //'Appl. on Gide Waitlist',
    GADMIN_STU_APP_RESUBMIT: 5, //"Resubmitted to GIDE",
    UNIV_APP_RECVD: 6, //'Appl. Recvd. by Univ',
    UNIV_APP_NEED_INFO: 7, //'Univ Needs More Info',
    UNIV_STU_APP_RESUBMIT: 8, //"Resubmitted to Univ",
    UNIV_APP_REJECTED: 9, //'Appl. on Univ Waitlist',
    UNIV_APP_ACCEPTED: 10, //'Appl. Accepted'
    UNIV_APP_ACP_NEED_INFO: 11, //'Univ Needs More Info',
    UNIV_APP_ACP_RESUBMIT: 12, //"Resubmitted to Univ",
    UNIV_APP_OFFER_SENT: 13, //'Offers Sent by Univ'
    STU_APP_REJECTED: 14, //"Offers Declined",
    STU_APP_ACCEPTED: 15 //"Offers Accepted",
  },  
  AdmissionStateInfoColors: {
    STU_ADM_START: "#0099C6",
    STU_APP_DONE: "#FF9900",
    GADMIN_APP_NEED_INFO: "#990099",
    GADMIN_APP_REJECTED: "#3366CC", 
    GADMIN_STU_APP_RESUBMIT: "#AAAA11",
    UNIV_APP_RECVD: '#DD4477',
    UNIV_APP_NEED_INFO: '#990099',
    UNIV_STU_APP_RESUBMIT: "#AAAA11",
    UNIV_APP_REJECTED: "#3366CC",
    UNIV_APP_ACCEPTED: "#0e8715",
    UNIV_APP_ACP_NEED_INFO: '#990099',
    UNIV_APP_ACP_RESUBMIT: "#AAAA11",
    UNIV_APP_OFFER_SENT: "#0e8715",
    STU_APP_REJECTED: "#DC3912",
    STU_APP_ACCEPTED: "#66AA00"
  },
  YjsDocsStateColors: {
    YJS_DOC_DOC_WR_OPEN: "#0099C6",
    YJS_DOC_SUBMIT_REVIEW: "#990099",
    YJS_DOC_REVIEW_COMMENTS: "#AAAA11",
    YJS_DOC_REVIEW_CLOSED: "#FF9900"
  },
  QUIZ_TYPE: {
    "quiz": "quiz",
    "competition": "competition",
    "game_hangman": "game_hangman",
    "counselling": "counselling",
    "testseries": "testseries"
  },
  ANALYTICS_TRACKER_STATUS_CODE : {
    INIT: "00",
    COMPLETE: "01",
    /*ERROR_CODES TO BE DEFINED HERE*/
  }  
};
export default config;
